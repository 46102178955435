import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CarrierSales } from './list';
import { CreateCarrierBidComponent } from '../carrier-bids/components/create-carrier-bid/create-carrier-bid.component';


const carrierSalesRoutes: Routes = [
  { path: '', component: CarrierSales, data: { title: 'Carrier Sales - WARP Admin' } },
  { path: 'create/:jobId', component: CreateCarrierBidComponent, data: { title: 'Carrier BID - WARP Admin' } },
  // { path: 'edit/:id', component: CreateCarrierBidComponent, data: { title: 'Carrier BID - WARP Admin' } },
  // { path: ':id', component: DetailCarrierBidComponent, data: { title: 'Carrier BID - WARP Admin' } },
  { path: ':id', redirectTo: '/dashboard/carrier-sales/:id', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(carrierSalesRoutes)],
  exports: [RouterModule]
})
export class CarrierSalesRoutingModule { }
